<template>
<form>
  <columns class="is-centered">
    <column class="is-5">
      <div class="is-flex is-align-items-center is-justify-content-center">
        <img class="guest-logo" src="/img/inspeckta-logo-white.svg" width="100" height="100" alt="inspeckta-logo">
      </div>
      <h2 class="title is-3 has-text-weight-light has-text-centered mb-6">Where Teamwork Happens</h2>
      <columns>
        <column>
          <text-input
            classes="is-medium is-rounded"
            required
            :value="user.email"
            @input="updateEmail">
            Email Address
          </text-input>
        </column>
      </columns>
      <columns>
        <column>
          <password-input
            classes="is-medium is-rounded"
            reveal-class="is-medium is-rounded"
            required
            reveal="toggle"
            :value="user.password"
            @input="updatePassword">
            Password
          </password-input>
        </column>
      </columns>
      <columns>
        <column>
          <submit-button
            class="is-medium mt-2 is-rounded is-fullwidth"
            :working="working"
            @submit="signin">
            Sign In
          </submit-button>
        </column>
      </columns>
      <columns class="mt-3 mb-6">
        <column>
          <router-link :to="{ name: 'forgot-password' }">Forgot Password</router-link>
        </column>
        <column class="has-text-right">
          <router-link :to="{ name: 'signup' }">Create a Free Account</router-link>
        </column>
      </columns>
      <columns class="mt-3">
        <column class="has-text-centered">
          <p>v{{ version }}</p>
        </column>
      </columns>
    </column>
  </columns>
</form>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
var pjson = require('../../../package.json')

export default {

  data: () => ({
    working: false
  }),

  methods: {
    ...mapMutations('auth', [
      'updateEmail',
      'updatePassword'
    ]),
    signin() {
      this.working = true
      this.$store.dispatch('auth/signin').then(() => {
        this.working = false
        this.$router.push({
          name: 'dashboard'
        })
      }).catch(error => {
        this.working = false
        if(error.response.status === 429) {
          this.$alert({
            title: 'Threat Detected', 
            message: 'Our systems have flagged your activity as suspicious. Please contact support for assistance.'
          })
        } else {
          this.$toast.error('We could not authenticate you. Please try again.')
        }
        
      })
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    version() {
      return pjson.version
    }
  }

}
</script>